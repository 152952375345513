<template>
  <div>
    <b-card>
      <b-table :fields="fields" :items="items" responsive="sm">
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(name)="data">
          {{ data.item.username }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <div class="text-center loader" v-if="isLoading">
        One moment getting automatum users...
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BProgress,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import { getAllUsers } from "../../../aws/Api";
import store from "@/store";
export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "name", label: "Name" },
        // A column that needs custom formatting
        { key: "email", label: "Email" },
      ],
      isLoading: false,
    };
  },
  // computed
  computed: {
    items: function () {
      return store.state["app-customers"].customers;
    },
  },
  // mounted
  async mounted() {
    try {
      this.isLoading = true;
      const response = await getAllUsers();
      store.commit("app-customers/UPDATE_CUSTOMERS", response);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  },
  // method
  methods: {
    searchCustomers() {
      // if (this.search) {
      //   const result = this.items.find((element) => {
      //     return element.product_name
      //       .toLowerCase()
      //       .trim()
      //       .includes(this.search.toLowerCase().trim());
      //   });
      //   if (result) {
      //     store.commit("app-products/UPDATE_PRODUCTS", [result]);
      //   } else {
      //     store.commit("app-products/UPDATE_PRODUCTS", this.backup);
      //   }
      // } else {
      //   console.log('no search', this.backup)
      //   store.commit("app-products/UPDATE_PRODUCTS", this.backup);
      // }
    },
  },
};
</script>